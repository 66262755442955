import React from 'react'
import { IRequestState } from './util/types'
import {
  IInputTypeProps,
  Input,
  StringSelect,
  NumberSelect,
  Label,
} from './util/Input'
import {
  ErrorMessage,
  BtnInput,
  Row,
  Col,
  UnusuallyLongRequestMessage,
} from '../shared'
import {
  handleChangeGenerator,
  filterObject,
  getDaysInMonth,
  getDaySelectOptions,
  getYearSelectOptions,
  getMapOptionsFromEnum,
  isDisabledGenerator,
  defaultErrorMsg,
  cleanPhoneNumber,
} from './util/helpers'
import { fetchUpdateAthlete } from '../../services/fetch'
import { EGender, EState, EMonth, TId } from '../../types'
import {
  IAthleteProfileBody,
  IAthleteProfileBodyOther,
} from '../../types/athlete'
import { birthdayObjToString } from '../../services/dateHelpers'
import * as session from '../../services/session'
import * as browser from '../../services/browser'
import { PROFILE_ROUTE } from '../../constants/routes'
import { M1 } from '../../constants/measurements'

type IProfileState = IAthleteProfileBody &
  IAthleteProfileBodyOther &
  IRequestState

class Athlete extends React.Component<Partial<IProfileState>, IProfileState> {
  private static inputTypes: {
    [s in keyof IAthleteProfileBody]: IInputTypeProps
  } = {
    firstName: {
      autoFocus: true,
      label: 'First Name',
      type: 'text',
      required: true,
      maxLength: 100,
    },
    lastName: {
      label: 'Last Name',
      type: 'text',
      required: true,
      maxLength: 100,
    },
    city: {
      label: 'City',
      type: 'text',
      required: false,
      placeholder: 'New York',
      maxLength: 100,
    },
    zipcode: {
      label: 'Zipcode',
      type: 'number',
      required: false,
      placeholder: '00000',
      maxLength: 5,
    },
    phoneNumber: {
      label: 'Phone',
      type: 'tel',
      required: false,
      placeholder: '(123)456-7890',
      maxLength: 16,
    },
  }

  private static isDisabled = isDisabledGenerator(Athlete.inputTypes)

  constructor(props: Partial<IProfileState>) {
    super(props)
    this.state = {
      pending: false,
      error: undefined,
      firstName: '',
      lastName: '',
      city: '',
      zipcode: '',
      phoneNumber: '',
      birthday: {
        year: undefined,
        day: undefined,
        month: undefined,
      },
      gender: undefined,
      state: undefined,
      ...filterObject(props),
    }

    this.handleChange = this.handleChange.bind(this)
  }

  public componentDidMount(): void {
    browser.setWarnUserBeforeLeaving()
  }

  public componentWillUnmount(): void {
    browser.setWarnUserBeforeLeaving(false)
  }

  private handleChange = handleChangeGenerator(this)

  private handleChangeBirthdaySelect(
    event: React.ChangeEvent<HTMLSelectElement>,
  ): void {
    event.preventDefault()
    const { name, value } = event.target
    const { birthday } = this.state
    const newBirthday = Object.assign(birthday, { [name]: value })
    this.setState({ birthday: newBirthday })
  }

  private handleSubmit(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault()
    if (Athlete.isDisabled(this.state)) return
    this.setState({ pending: true, error: undefined })

    // Find changes between the props and the state
    const body: Record<string, any> = {}
    const {
      birthday,
      state,
      firstName,
      lastName,
      city,
      phoneNumber,
      zipcode,
      gender,
    } = this.state
    let birthdayStr: string | undefined
    try {
      birthdayStr = birthdayObjToString(birthday)
    } catch (err) {
      return this.setState({
        pending: false,
        error: err.message || defaultErrorMsg,
      })
    }

    // Strip out whitespace and punctuation
    const cleanedPhoneNumber = cleanPhoneNumber(phoneNumber)
    const providedFields: Record<string, any> = {
      birthday: birthdayStr,
      state,
      firstName,
      lastName,
      city,
      gender,
      phoneNumber: cleanedPhoneNumber,
      zipcode,
    }
    Object.keys(providedFields).forEach((key: string) => {
      const stateValue = providedFields[key]
      const propsValue = (this.props as Record<string, any>)[key]
      if (stateValue && stateValue !== propsValue) {
        body[key] = stateValue
      }
    })

    if (!Object.keys(body).length) {
      return this.setState({ pending: false, error: 'No fields changed.' })
    }

    // We have already checked that the user is logged in
    const userId: TId = session.getId() as TId
    fetchUpdateAthlete(userId, body)
      .then(() => session.redirect(PROFILE_ROUTE))
      .catch(err =>
        this.setState({
          pending: false,
          error: err.message || defaultErrorMsg,
        }),
      )
  }

  public render(): React.ReactElement {
    const {
      error,
      pending,
      birthday,
      state,
      firstName,
      lastName,
      city,
      phoneNumber,
      zipcode,
      gender,
    } = this.state
    const { month, day, year } = birthday || {}
    const numDays = getDaysInMonth(month)
    const isDisabled = Athlete.isDisabled(this.state)

    return (
      <form
        onSubmit={(e: React.FormEvent<HTMLFormElement>): void =>
          this.handleSubmit(e)
        }
      >
        <ErrorMessage message={error} />
        <UnusuallyLongRequestMessage pending={pending} />

        <Row margin={M1}>
          <Col sm={12} md={6} margin={M1}>
            <Input
              {...Athlete.inputTypes.firstName}
              value={firstName}
              name="firstName"
              onChange={this.handleChange}
            />
          </Col>
          <Col sm={12} md={6} margin={M1}>
            <Input
              {...Athlete.inputTypes.lastName}
              value={lastName}
              name="lastName"
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <Input
          {...Athlete.inputTypes.phoneNumber}
          value={phoneNumber}
          name="phoneNumber"
          onChange={this.handleChange}
        />
        <Label label="Location" />
        <Row margin={M1}>
          <Col sm={12} md={5} margin={M1}>
            <Input
              smallLabel
              {...Athlete.inputTypes.city}
              value={city}
              name="city"
              onChange={this.handleChange}
            />
          </Col>
          <Col sm={12} md={3} margin={M1}>
            <StringSelect
              smallLabel
              label="State"
              value={state}
              name="state"
              onChange={this.handleChange}
              options={getMapOptionsFromEnum(EState)}
            />
          </Col>
          <Col sm={12} md={4} margin={M1}>
            <Input
              smallLabel
              {...Athlete.inputTypes.zipcode}
              value={zipcode}
              name="zipcode"
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <Label label="Birthday" />
        <Row margin={M1}>
          <Col margin={M1} md={5}>
            <StringSelect
              label="Month"
              smallLabel
              value={month}
              name="month"
              onChange={(e): void => this.handleChangeBirthdaySelect(e)}
              options={getMapOptionsFromEnum(EMonth)}
            />
          </Col>
          <Col margin={M1} md={3}>
            <NumberSelect
              label="Day"
              smallLabel
              value={day}
              name="day"
              onChange={(e): void => this.handleChangeBirthdaySelect(e)}
              options={getDaySelectOptions(numDays)}
            />
          </Col>
          <Col margin={M1} md={4}>
            <NumberSelect
              label="Year"
              smallLabel
              value={year}
              name="year"
              onChange={(e): void => this.handleChangeBirthdaySelect(e)}
              options={getYearSelectOptions()}
            />
          </Col>
        </Row>
        <StringSelect
          label="Gender"
          smallLabel
          value={gender}
          name="gender"
          onChange={this.handleChange}
          options={getMapOptionsFromEnum(EGender)}
        />
        <BtnInput
          pending={pending}
          type="submit"
          value={pending ? 'Updating Profile...' : 'Update Profile'}
          disabled={isDisabled}
          fullWidth
        />
      </form>
    )
  }
}

export default Athlete
