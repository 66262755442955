import React, { useState, useEffect } from 'react'

import EditTrainer from '../../components/forms/Trainer'
import EditAthlete from '../../components/forms/Athlete'
import * as session from '../../util/session'
import {
  IAthleteResponse,
  ITrainerResponse,
  fetchUserData,
} from '../../util/fetch'
import { stringToBirthdayObj } from '../../util/dateHelpers'
import Layout from '../../templates/LiveLayout'
import {
  ITrainerProfileBody,
  ITrainerProfileBodyOther,
} from '../../types/trainer'
import {
  IAthleteProfileBody,
  IAthleteProfileBodyOther,
} from '../../types/athlete'
import * as S from '../../styles/login-live.styles'
import {
  IconCircle,
  EditIcon,
  ErrorMessage,
  LoadingSpinner,
} from '../../components/shared'
import { WHITE } from '../../constants/colors'
import { InitialsCircle } from '../../components/profile/InitialsCircle'
import { EditProfPicModal } from '../../components/profile/EditProfPicModal'
import { PageRendererProps } from 'gatsby'
import { LOGIN_ROUTE } from '../../constants/routes'
import { withErrorHandler } from '../../components/errorHandler'

const getTrainerData = (
  data: ITrainerResponse,
): Partial<ITrainerProfileBody & ITrainerProfileBodyOther> => {
  const { zipcode, birthday, ...rest } = data
  return {
    zipcode: (zipcode && `${zipcode}`) || '',
    birthday: (birthday && stringToBirthdayObj(birthday)) || undefined,
    ...rest,
  }
}

const getAthleteData = (
  data: IAthleteResponse,
): Partial<IAthleteProfileBody & IAthleteProfileBodyOther> => {
  const { zipcode, birthday, ...rest } = data
  return {
    zipcode: (zipcode && `${zipcode}`) || '',
    birthday: (birthday && stringToBirthdayObj(birthday)) || undefined,
    ...rest,
  }
}

type TData = IAthleteResponse | ITrainerResponse | undefined

const EditProfileComponent = ({ data }: { data: TData }) => {
  const isTrainer = session.isTrainer()

  const profilePic = data
    ? isTrainer
      ? // @ts-ignore
        data.profilePictureURL
      : // @ts-ignore
        data.profilePictureUrl
    : undefined

  const [editProfPicActive, setEditProfPicActive] = useState<boolean>(false)
  const [profilePictureURLState, setProfilePictureURLState] = useState<
    string | undefined
  >(profilePic)

  const EditIconCircle = ({}): React.ReactElement => (
    <IconCircle
      onClick={(): void => setEditProfPicActive(true)}
      style={{
        position: 'absolute',
        right: 'calc(15% - 1rem)',
        bottom: 'calc(15% - 1rem)',
      }}
    >
      <EditIcon color={WHITE} />
    </IconCircle>
  )

  return (
    <S.ImageWraper>
      {profilePictureURLState ? (
        <S.Image img={profilePictureURLState}>
          <EditIconCircle />
        </S.Image>
      ) : (
        <InitialsCircle
          firstName={data && data.firstName}
          lastName={data && data.lastName}
        >
          <EditIconCircle />
        </InitialsCircle>
      )}
      <EditProfPicModal
        callback={(res: IAthleteResponse): void =>
          // @ts-ignore
          setProfilePictureURLState(res.profilePictureURL)
        }
        active={editProfPicActive}
        setActive={setEditProfPicActive}
      />
    </S.ImageWraper>
  )
}

const EditProfilePage = (props: PageRendererProps): React.ReactElement => {
  const [pending, setPending] = useState<boolean>(true)
  const [error, setError] = useState<string | undefined>(undefined)
  const [data, setData] = useState<TData>(undefined)

  const userId = session.getId()
  const isTrainer = session.isTrainer()

  session.redirectIfNotLoggedIn(LOGIN_ROUTE)

  useEffect(() => {
    if (!userId) return
    fetchUserData(userId, setData, setPending, setError)
  }, [userId])

  return (
    <Layout title="Edit Profile" location={props.location}>
      <S.Container>
        <S.Wrap>
          <ErrorMessage message={error} />
          <EditProfileComponent data={data} />
          {pending || !data ? (
            <LoadingSpinner />
          ) : isTrainer ? (
            <EditTrainer {...getTrainerData(data as ITrainerResponse)} />
          ) : (
            <EditAthlete {...getAthleteData(data as IAthleteResponse)} />
          )}
        </S.Wrap>
      </S.Container>
    </Layout>
  )
}

export default withErrorHandler(EditProfilePage)
